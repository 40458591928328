/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect, useContext } from 'react'
import { useIonModal } from '@ionic/react'
import Context from '../context'
import getConfig from 'next/config'
import { useAddToCart } from '../hooks/cart.hooks'
import RemoveItemModal from './RemoveItemModal'
import SVG from './SVG'
import { setCartEventsData } from '../utils/analytics'

const { publicRuntimeConfig } = getConfig()

const AddToCart = ({ product, cartQuantity, forceDelete = false }) => {
  const { state } = useContext(Context)
  const [animated, setAnimated] = useState(false)
  const makeAddToCartCall = useAddToCart()
  const [counterTotal, setCounterTotal] = useState(0)

  useEffect(() => {
    const counter = state.cart?.products?.reduce((prev, cur) => {
      return prev + cur.quantity
    }, 0)

    setCounterTotal(counter)
  }, [state.cart])

  const addItemToCart = async (qty, e) => {
    setAnimated(true)
    e && e.preventDefault()
    const el = document.querySelector('.add-to-cart__hidden-div')
    if (el) {
      const updatedQty = qty === 1 ? cartQuantity + 1 : (cartQuantity !== 0 && cartQuantity - 1)
      if (counterTotal !== 0) { el.textContent = (updatedQty === 0 ? 'product is removed' : `product is ${(qty === 1 ? 'added to' : 'removed from')} cart, total quantity is ${updatedQty}`) }
    }
    makeAddToCartCall(state.cart.store?.storenumber || publicRuntimeConfig.DEFAULT_STORE, product.upc, product.digital_sku, product.sku, qty, false, e).then((resData) => {
      setAnimated(false)
    })
    handleCartAnalyticsEvent(qty)
  }

  const handleCartAnalyticsEvent = (qty) => {
    setCartEventsData(qty, product, state.cart)
  }

  const handleDismiss = () => {
    dismiss()
  }
  const [present, dismiss] = useIonModal(RemoveItemModal, { hide: handleDismiss, product, removeItem: addItemToCart })

  useEffect(() => {
    if (forceDelete) {
      present()
    }
  }, [forceDelete])

  return (
    <div className="add-to-cart">
      {cartQuantity !== 0
        ? <div className="add-to-cart">
          <div className='add-to-cart__button'>
            {cartQuantity === 1 &&
              <button className={`add-to-cart__button-trash  ${animated && 'add-to-cart__button-minus__animated'}`} onClick={(e) => {
                e.preventDefault()
                present()
              }
              } disabled={animated} ><SVG name="trash" label="trash" size="md" /></button>
            }
            {cartQuantity > 1 &&
              <button className={`add-to-cart__button-minus  ${animated && 'add-to-cart__button-minus__animated'}`} disabled={animated}
                onClick={(e) => addItemToCart(-1, e)
                }>{!animated && <SVG name="quantity-minus" label="quantity-minus" size="md" />}</button>
            }
            {!animated && <span>{cartQuantity}</span>}
            <button className={`add-to-cart__button-plus ${animated && 'add-to-cart__button-plus__animated'}`} disabled={animated} onClick={(e) => addItemToCart(1, e)
            }>{!animated && <SVG name="quantity-plus" label="quantity-plus" size="md" />}</button>
          </div>
        </div>
        : <div className='add-to-cart__new-item'>
          <button
            className={`btn__primary ${!animated && 'unchecked_cart'} ${animated && 'btn__primary-animated'}`}
            disabled={animated}
            key={product.upc}
            aria-label={`add to cart ${product?.digital_product_name ?? product?.product_name ?? ''}`}
            data-upc={product.upc}
            data-name={(product?.digital_product_name ?? product?.product_name ?? '').toLowerCase()}
            data-price={parseFloat(product.discount_price ? product.discount_price : product.price).toFixed(2)}
            id={`add-to-cart-${product.slug}`}
            onClick={(e) => {
              e.preventDefault()
              addItemToCart(1, e)
              setAnimated(true)
            }}
          >
          </button></div>}
      <div className='add-to-cart__hidden-div' role='status' aria-live='polite'></div>
    </div>
  )
}
export default AddToCart
