/* eslint-disable @next/next/no-img-element */
import { useEffect, useRef, useState } from 'react'
import { IonModal } from '@ionic/react'
import { PinchZoom } from 'react-ivy-pinch-zoom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Pagination, FreeMode, Navigation } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/keyboard'
import 'swiper/css/thumbs'
import SVG from './SVG'

const MainCarouselImage = ({ data }) => {
  const brokenImage = '/images/popshelf-broken-onwhite.svg'
  const [imageSrc, setImageSrc] = useState(data || brokenImage)

  return <img src={imageSrc} alt="product image" onError={() => setImageSrc(brokenImage)} />
}

const MainCarousel = ({ imageUrls }) => {
  const [backgroundPosition, setBackgroundPosition] = useState('0% 0%')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalImage, setModalImage] = useState('')
  const [zoomInClass, setZoomInClass] = useState('')
  const [hasHovered, setHasHovered] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const outsideImageRef = useRef(null)

  useEffect(() => {
    if (isHovering && hasHovered) {
      setZoomInClass('enable-zoom')
    } else {
      outsideImageRef.current.addEventListener('mousemove', handleMouseOut)
    }
    return () => {
      outsideImageRef.current.removeEventListener('mousemove', handleMouseOut)
    }
  }, [isHovering, hasHovered])

  const splitUrl = imageUrls.split(',')

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = ((e.pageX - left) / width) * 100
    const y = ((e.pageY - top) / height) * 100
    setBackgroundPosition(`${x}% ${y}%`)
  }

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
    if (!hasHovered) {
      setHasHovered(true)
    }
  }

  const createImageURLDesktop = (data, i) => {
    return (
      <SwiperSlide key={`image-div-${i}`}>
        <figure
          key={`image-div-${i}`}
          onMouseMove={handleMouseMove}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          className={`main-carousel__figure ${zoomInClass}`}
          style={{ backgroundPosition, backgroundImage: `url(${data})` }}
        >
          <MainCarouselImage data={data} />
        </figure>
      </SwiperSlide>
    )
  }

  const createImageURLMobile = (data, i) => {
    return (
      <SwiperSlide key={`image-div-mobile-${i}`}>
        <div
          onClick={() => {
            setModalIsOpen(true)
            setModalImage(data)
          }}
        >
          <MainCarouselImage data={data} />
        </div>
      </SwiperSlide>
    )
  }

  const swiperConfigMobile = {
    modules: [FreeMode, Pagination, Navigation, Keyboard],
    spaceBetween: 16,
    keyboard: true,
    watchOverflow: true,
    pagination: { clickable: true },
    navigation: {
      nextEl: '.swiper-button-next-pdp',
      prevEl: '.swiper-button-prev-pdp'
    }
  }

  const swiperConfigDesktop = {
    modules: [FreeMode, Pagination, Navigation, Keyboard],
    navigation: {
      nextEl: '.swiper-button-next-pdp',
      prevEl: '.swiper-button-prev-pdp'
    },
    spaceBetween: 16,
    keyboard: true,
    watchOverflow: true,
    pagination: {
      el: '.swiper-pagination-pdp',
      clickable: true,
      renderBullet: (index, className) => {
        return (
          '<img class="' +
          className +
          '" src="' +
          splitUrl[index] +
          '" alt="alternative images" onerror="this.onerror=null;this.src=\'/images/popshelf-broken-onwhite.svg\';" />'
        )
      }
    }
  }

  return (
    <div className="main-carousel" key={imageUrls} ref={outsideImageRef}>
      <div className="main-carousel--mobile swiper__section">
        <Swiper {...swiperConfigMobile} slidesPerView={'auto'}>
          {imageUrls && imageUrls.split(',').map(createImageURLMobile)}
        </Swiper>
      </div>
      <div className="main-carousel--desktop swiper__section">
        <Swiper {...swiperConfigDesktop} slidesPerView={'auto'}>
          {imageUrls && imageUrls.split(',').map(createImageURLDesktop)}
          <div className={'swiper-button-prev-pdp swiper-navbutton swiper-navbutton-prev'}></div>
          <div className={'swiper-button-next-pdp swiper-navbutton swiper-navbutton-next'}></div>
        </Swiper>
        <div className={`swiper-pagination-pdp ${splitUrl.length > 5 ? 'swiper-pagination-pdp-wrap' : ''}`}></div>
      </div>
      {typeof window !== 'undefined' && (
        <IonModal
          isOpen={modalIsOpen && modalImage !== ''}
          className="modal--fullscreen"
          onDidDismiss={() => setModalIsOpen(false)}
        >
          <section>
            <button onClick={() => setModalIsOpen(false)} className="close" aria-label="close">
              <SVG name="close" label="Close" size="md" />
            </button>
            <div className="carousel__product-modal">
              <div className="carousel__product-container-mobile">
                <PinchZoom imgPath={modalImage} />
              </div>
              <div className="carousel__product-modal-zoom">
                <p className="carousel__product-modal-text">PINCH IMAGE TO ZOOM IN</p>
              </div>
            </div>
          </section>
        </IonModal>
      )}
    </div>
  )
}

export default MainCarousel
